@font-face {
  font-family: VCR_OSD_Mono;
  src: url("VCR_OSD_MONO.aafd899e.ttf");
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#loading-screen {
  color: #fff;
  z-index: 1000;
  opacity: 1;
  background: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#loading-screen .container {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 30%;
  display: flex;
}

#loading-screen img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  display: block;
}

body {
  background-color: #000102;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100svh;
  margin: 0;
  font-family: VCR_OSD_Mono;
  display: flex;
  overflow: hidden;
}

td {
  min-width: 30%;
}

#game-container {
  --container-height: 800px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  height: 100%;
  max-height: 800px;
  display: flex;
  position: relative;
}

#game-container canvas {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.button {
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  text-align: center;
  touch-action: manipulation;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  width: 150px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 10px 0 11px;
  font-size: 13px;
  line-height: 29px;
  text-decoration: none;
  display: inline-block;
}

#wiki-wrapper {
  max-height: calc(var(--container-height)  - 14rem);
  color: #000;
  box-sizing: border-box;
  background-color: #fff5d4;
  width: 100%;
  max-width: 70vw;
  height: 100%;
  padding: 4rem;
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow-y: auto;
  transform: translateX(-50%);
}

@media (width <= 768px) {
  #wiki-wrapper {
    max-width: 100vw;
    padding: 2rem;
  }
}

#wiki-text {
  font-size: 16px;
  line-height: 1.4;
  display: block;
  position: relative;
}

#wiki-text td {
  min-width: 30%;
  margin: 5px;
}

#hide-wiki-content {
  all: unset;
  cursor: pointer;
  font-family: inherit;
  font-size: x-large;
  font-weight: bold;
  position: absolute;
  top: 20px;
  right: 20px;
}

#back-btn {
  display: none;
}

#close-pdf {
  z-index: 1;
  position: absolute;
  top: 50px;
}

#pdf-viewer-container {
  color: #fff;
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: VCR_OSD_Mono;
  display: none;
}

#pdf-object {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

#article-img-container {
  color: #000;
  box-sizing: border-box;
  background-color: #020d26;
  width: 100%;
  max-width: 70%;
  height: auto;
  max-height: 100%;
  padding: 20px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
}

#article-img-container img {
  width: 100%;
  height: auto;
  display: block;
}

#hide-article-img {
  all: unset;
  color: #fff;
  cursor: pointer;
  padding-bottom: 20px;
  font-family: inherit;
  font-size: x-large;
  font-weight: bold;
}

@media (width >= 600px) {
  #wiki-text {
    font-size: 16px;
  }

  #hide-article-img {
    padding-bottom: 14px;
    font-size: 16px;
  }

  #article-img-container {
    padding: 14px;
  }

  #hide-wiki-content {
    font-size: 16px;
  }
}

@media (width >= 1000px) {
  #wiki-text {
    font-size: 18px;
  }

  #hide-wiki-content {
    font-size: x-large;
  }

  #hide-article-img {
    padding-bottom: 20px;
    font-size: x-large;
  }

  #article-img-container {
    padding: 20px;
  }
}

@media (width <= 599px) {
  #wiki-text {
    font-size: 14px;
  }
}

/*# sourceMappingURL=index.e15c6a53.css.map */
