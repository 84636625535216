@font-face {
  font-family: "VCR_OSD_Mono";
  src: url(../resources/fonts/VCR_OSD_MONO.ttf);
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 1s ease-out;
  opacity: 1;
}

#loading-screen .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30%;
  box-sizing: border-box;
}

#loading-screen img {
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

body {
  font-family: "VCR_OSD_Mono";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100svh;
  margin: 0;
  background-color: #000102;
  overflow: hidden;
}

td {
  min-width: 30%;
}

#game-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  max-height: 800px;
  --container-height: 800px;
  width: 100%;
  height: 100%;
}

#game-container canvas {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.button {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 13px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 150px;
}

#wiki-wrapper {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  max-width: 70vw;
  height: 100%;
  max-height: calc(var(--container-height) - 14rem);
  background-color: rgb(255, 245, 212);
  color: black;
  overflow-y: auto;
  padding: 4rem;
  box-sizing: border-box;
  display: none; /* Hidden by default */
}

@media (max-width: 768px) {
  #wiki-wrapper {
    max-width: 100vw;
    padding: 2rem;
  }
}

#wiki-text {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 1.4;
}

#wiki-text td {
  min-width: 30%;
  margin: 5px;
}

/* close button for wiki content */
#hide-wiki-content {
  all: unset;
  font-family: inherit;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: x-large;
  font-weight: bold;
}

#back-btn {
  display: none;
}

#close-pdf {
  position: absolute;
  top: 50px;
  z-index: 1;
}

#pdf-viewer-container {
  font-family: "VCR_OSD_Mono";
  display: none;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
}
#pdf-object {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

#article-img-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 70%;
  height: auto;
  max-height: 100%;
  background-color: #020d26;
  color: black;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: none; /* Hidden by default */
}

#article-img-container img {
  display: block;
  width: 100%;
  height: auto;
}

#hide-article-img {
  all: unset;
  color: #fff;
  font-family: inherit;
  cursor: pointer;
  font-size: x-large;
  font-weight: bold;
  padding-bottom: 20px;
}

/* For screens wider than 600px */
@media (min-width: 600px) {
  #wiki-text {
    font-size: 16px;
  }
  #hide-article-img {
    font-size: 16px;
    padding-bottom: 14px;
  }
  #article-img-container {
    padding: 14px;
  }
  #hide-wiki-content {
    font-size: 16px;
  }
}

/* For screens wider than 1000px */
@media (min-width: 1000px) {
  #wiki-text {
    font-size: 18px;
  }
  #hide-wiki-content {
    font-size: x-large;
  }
  #hide-article-img {
    font-size: x-large;
    padding-bottom: 20px;
  }
  #article-img-container {
    padding: 20px;
  }
}

/* For screens narrower than 600px */
@media (max-width: 599px) {
  #wiki-text {
    font-size: 14px;
  }
}
